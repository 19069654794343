.swiper-slide-active.protfolio-card>.protfolio-card-child {
    /* background-color: red; */
    transform: scale(1.2);
    /* transition: transform 1s  ease; */
}

.spin-2000 {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.spin-5000 {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}



/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.special-scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 50px;
    background-color: #cd93480f;
}

.special-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #982b2b00;
    border-radius: 5px;
}

.special-scrollbar::-webkit-scrollbar-thumb {
    background: #B9884F;
    border-radius: 10px;
    box-shadow: 0 0 5px rgb(77, 77, 77);
}



/* ================================ */
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #34325c6c;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #A8CF45;
    /* background: #565cc8; */
    height: 20px;
    border-radius: 10px;
}

.perfomance_fix{
    transform: translate3d(0, 0, 0);
}